import React, { Component } from "react";
import Footer from "../components/Footer";
import PageHeader, { PageHeaderSpacer } from "../components/PageHeader";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import GreyBlock from "../components/GreyBlock";
import ButtonWithBg from "../components/ButtonWithBg";
import { Contact } from "../components/Contact";
import { Helmet, Accordion, BuyTicketsFixedBottom } from "../components";
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_DEADLINE,
  CS_FIRST_PRIZE_DRAWN,
} from "../store/service";
import styled from "styled-components";

const TopRow = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-top: ${competitionState == CS_FIRST_PRIZE_DRAWN ? "15px" : "0px"};
  }
`;

export default class PastWinners extends Component {
  render() {
    return (
      <TopRow className="your-impact-page">
        <Helmet
          title="Past Winners | Central District Football Club Lottery"
          link="/past-winners"
          description="Congratulations to our past Central District Football Club Lottery winners. Find a full list of winners on the page below."
        />

        <PageHeader showBack={false} {...this.props} />
        <PageHeaderSpacer />
        <BuyTicketsFixedBottom
          competitionClosed={competitionState == CS_FIRST_PRIZE_DRAWN}
        />

        <div style={{ marginTop: "7%" }} className="impact-intro">
          <Container>
            <div className="subtitle lg">Past Winners</div>
            <div className="text">
              <p>
                Congratulations to our past Central District Football Club
                Lottery winners.{" "}
              </p>

              <strong>
                Central District Football Club Lottery November 2022
              </strong>
              <div className="winners-table">
                <table>
                  <tr>
                    <th>Prize</th>
                    <th>Name</th>
                    <th>Ticket Number</th>
                  </tr>
                  {/* <tr>
                    <td>First Prize</td>
                    <td>Kelly B.</td>
                    <td>#WCHF005_06276</td>
                  </tr> */}
                  <tr>
                    <td>Early Bird Prize</td>
                    <td>Kevin J.</td>
                    <td>CDFC001_00244</td>
                  </tr>
                  {/* <tr>
                    <td>VIP Draw</td>
                    <td>Malcolm W.</td>
                    <td>#WCHF005_01244</td>
                  </tr> */}
                </table>
              </div>

              {/* <Accordion
                title="Women's & Children's Hospital Foundation Lottery September 2021"
                text=" <strong>
                Women’s &amp; Children’s Hospital Foundation Lottery September
                2021
              </strong>
              <div class='winners-table'>
                <table>
                  <tr>
                    <th>Prize Winner</th>
                    <th>Name</th>
                    <th>Ticket Number</th>
                  </tr>
                  <tr>
                    <td>First Prize</td>
                    <td>Ben A.</td>
                    <td>#WCHF004_02084</td>
                  </tr>
                  <tr>
                    <td>Early Bird Prize</td>
                    <td>Brooke C.</td>
                    <td>#WCHF004_04448</td>
                  </tr>
                  <tr>
                    <td>VIP Draw</td>
                    <td>Darren H.</td>
                    <td>#WCHF004_00049</td>
                  </tr>
                </table>
              </div>
"
              ></Accordion>

              <Accordion
                title="Women's & Children's Hospital Foundation Lottery February 2021"
                text="  <strong>
                Women’s &amp; Children’s Hospital Foundation Lottery February
                2021
              </strong>
              <div class='winners-table'>
 <table>
                  <tr>
                    <th>Prize Winner</th>
                    <th>Name</th>
                    <th>Ticket Number</th>
                  </tr>
                  <tr>
                    <td>First Prize</td>
                    <td>Debbie A.</td>
                    <td>#WCHF003_08910</td>
                  </tr>
                  <tr>
                    <td>Early Bird Prize</td>
                    <td>Maria. D</td>
                    <td>#WCHF003_00129</td>
                  </tr>
                  <tr>
                    <td>VIP Draw</td>
                    <td>S. Rocca</td>
                    <td>#WCHF003_00646</td>
                  </tr>
                </table>
              </div>
"
              ></Accordion>

              <Accordion
                title="Women's & Children's Hospital Foundation Lottery September 2020"
                text="  <strong>
                Women’s &amp; Children’s Hospital Foundation Lottery September 2020
              </strong>
              <div class='winners-table'>
  <table>
                  <tr>
                    <th>Prize Winner</th>
                    <th>Name</th>
                    <th>Ticket Number</th>
                  </tr>
                  <tr>
                    <td>First Prize</td>
                    <td>Alison. D</td>
                    <td>#WCHF002_00468</td>
                  </tr>
                  <tr>
                    <td>Early Bird Prize</td>
                    <td>G. Bell</td>
                    <td>#WCHF002_04928</td>
                  </tr>
                </table>
              </div>
"
              ></Accordion> */}
              <div style={{ marginTop: 50 }}></div>
            </div>
          </Container>
        </div>

        {/* <a name="signup" id="signup" /> */}
        <div className="contact-and-partners">
          <Container>
            <div className="divider" />
            <Contact />
          </Container>
        </div>

        <Footer />
      </TopRow>
    );
  }
}
