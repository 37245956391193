import React, { Component } from 'react';
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import Countdown from './Countdown';
import ButtonWithBg from './ButtonWithBg';
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_CLOSED,
  CS_EARLY_BIRD_DEADLINE,
  CS_FIRST_PRIZE_DRAWN,
  CS_PRELAUNCH,
  CS_VIP_OPEN,
} from '../store/service';
import styled from 'styled-components';

const PageHeaderDiv = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 0px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  top: 0px;
  transition: top 0.5s ease-out;

  @media only screen and (max-width: 1000px) {
    top: ${(props) => (props.sticky ? (competitionState == CS_FIRST_PRIZE_DRAWN ? '0' : '0px') : '0px')};
  }
`;

const HeaderRow1 = styled.div`
  height: 80px;
  min-height: 80px;
  background: #db0032;
  width: 100%;
  color: #ffffff;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
    line-height: 20px;
    height: ${competitionState == CS_FIRST_PRIZE_DRAWN ? '80px' : '80px'};
    min-height: ${competitionState == CS_FIRST_PRIZE_DRAWN ? '80px' : '80px'};
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 200%;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    margin-left: 30%;
    transform: scale(60%);
    justify-content: center;
  }
`;

export default class PageHeader extends Component {
  static defaultProps = {
    showBack: true, // showBack true means show the back button
  };

  constructor(props) {
    super(props);

    this.state = {
      sticky: false,
      showMenu: false,
    };

    window.addEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    var y = window.scrollY;
    if (y >= 100) {
      if (!this.state.sticky) this.setState({ sticky: true });
    } else {
      if (this.state.sticky) this.setState({ sticky: false });
    }
  };

  render() {
    const { showBack, buttonClick, competitionClosed, noButtons, competitionState, secondaryAnnouncement } = this.props;
    console.log('PROPS', this.props);
    return (
      <PageHeaderDiv className="page-header" sticky={this.state.sticky}>
        <HeaderRow1>
          <Container className="announcementHeader"></Container>
          <CountdownContainer>
            <div style={{ minWidth: '260px' }}>Lottery closes in:</div>
            <Countdown countdown={this.props.countdown} />
          </CountdownContainer>
        </HeaderRow1>

        <Row className="header-row-2">
          <Container className="header-container">
            <div className="logo">
              <a href="/">
                {/* <img className={this.state.sticky ? "stickyLogo  smallLogo" : "stickyLogo"} src="/images/mhl-logo.png" /> */}
                <img className={'stickyLogo'} src="/images/CDFCLogo.png" />
              </a>
            </div>

            <div className="win">
              {/*secondaryAnnouncement && 
                                <Ticker>
                                    {() => <span>{secondaryAnnouncement}</span>}
                                </Ticker>
                            */}
            </div>

            <div className="phone-buy-back-container">
              <div className="buy-back">
                {competitionClosed && !noButtons && (
                  <ButtonWithBg href="#signup" label="VIP SIGN UP" className="small" />
                )}
                {!competitionClosed && !showBack && (
                  <ButtonWithBg href="buy-tickets" /*onClick={buttonClick}*/ label="BUY TICKETS" className="small" />
                )}
                {showBack && (
                  <ButtonWithBg
                    href={buttonClick ? null : '/'}
                    label="BACK"
                    onClick={buttonClick}
                    className="small back"
                  />
                )}
              </div>
            </div>

            <div className="menu">
              {competitionState == CS_PRELAUNCH ? null : (
                <Button onClick={() => this.setState({ showMenu: true })}>
                  <img src="/images/menu.svg" />
                </Button>
              )}
            </div>
          </Container>
        </Row>

        <Row className={`header-row-3 ${this.state.showMenu ? 'visible' : 'hidden'}`}>
          <Container className="header-container">
            {/* <div>
              <a href="/our-prizes">Our Prizes</a>
            </div> */}
            <div>
              <a href="/your-impact">Your Impact</a>
            </div>
            <div>
              <a href="/past-winners">Past Winners</a>
            </div>
            <div>
              <a href="/frequently-asked-questions">FAQs</a>
            </div>
            <div>
              <Button onClick={() => this.setState({ showMenu: false })}>
                <img src="/images/close.svg" />
              </Button>
            </div>
          </Container>
        </Row>
      </PageHeaderDiv>
    );
  }
}

export const PageHeaderSpacer = (props) => <div class="page-header-page-margin" />;
